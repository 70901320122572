


.App {
  text-align: center;
  
}

.App-logo {
  height: 15vmin;
  pointer-events: none;
}

.App-header {
  background-color:#0c0b0b;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 
}

.App-link {
  color: #61dafb;
}


body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #0c0b0b; 
  color: #fff;
  min-height: 100vh;
  font-family: "Red Hat Display", sans-serif;
  
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: flex-start; 
  margin-top: 0px; 
}




