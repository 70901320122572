.contact-container {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    background-color: #0c0b0b; 
    margin-top: 20px
}
.contact-form .input-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    
}

.contact-form .input-group {
    flex: 1;
    margin-right: 10px;
}

.contact-form .input-group:last-child {
    margin-right: 0;
}
.contact-image {
    width: 400px;
    height: 100px; 
    background-image: url('img/contact.png');
    background-size: cover;
    background-position: center;
}
form {
    max-width: 500px;
    margin: 0 auto;
    padding: 2rem;
    background-color: 0c0b0b; 
    color: #fff; 
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


input, textarea {
    width: 100%;
    padding: .8rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: .1rem;
    background-color: #fff; 
    color: #333; 
    border-radius: 40px;
    font-family: "Red Hat Display", sans-serif;
}

button {
    background-color: #991200;
    color: #0c0b0b;
    padding: 10px 20px;
    border: none;
    border-radius: 40px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    font-family: "Red Hat Display", sans-serif;
}

button:hover {
    background-color: #600900;
}

