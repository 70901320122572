

.gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0px; 
  }
  
  .image-container {
    position: relative;
    cursor: pointer;
  }
  
  .image-container img {
    width: 400px;
    height: 400px;
    display: block;
    object-fit:cover;
  }
  
  .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5); 
    color: #fff; 
    visibility: hidden; 
    opacity: 0;
    width: 100%; 
    height: 100%; 
    display: flex;
    transition: opacity 0.3s, visibility 0.3s;
    justify-content: center;
    align-items: center;
  }
  
  .image-container:hover .overlay {
    visibility: visible;
    opacity: 1;
  }
  